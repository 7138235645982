// Mobile Version
@media (max-width: 640px) {
  #hero {
    padding-top: 0px;
    height: 80svh;
    max-height: 650px;

    #slogan {
      font-size: 40px;
      line-height: 45px;
      min-height: 150px;
    }

    #hero_bg {
      background-image: url(../assets/images/herobackgroundmobile.webp);
      background-size: cover;
      // background-repeat: no-repeat;
    }
  }

  #mobile_mockup {
    background-image: url(../assets/images/mobile_mockup.webp);
    background-repeat: no-repeat;
    background-position: right center;
    background-size: contain;
  }

  #mobile_mockup_overlay {
    background: linear-gradient(180deg, rgba(243, 243, 243, 0.00) 0%, #F3F3F3 100%)
  }

  #clients {
    overflow: hidden;

    #clients_container {
      width: 1400px;
    }
  }

  #footer_logo {
    height: 27px;
    background-size: 100%;
  }

  #footer {
    #back_to_top {
      padding: 16px 13px;

      img {
        height: 20px;
        transform: translateX(-4px);
      }
    }
  }

  #menu_bar #menu-container {
    border: none;
    background-color: transparent;
  }

  .expandable-content {
    overflow: hidden;
    height: 0;
    transition: height 1s ease;
  }

  .animate {
    animation: righttoleft 15s infinite linear;
  }

  @keyframes righttoleft {
    from {
      transform: translateX(0%);
    }

    to {
      transform: translateX(-100%);
    }
  }

  /* MOBILE NAVIGATION */
  .page {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transform: translateX(100%);
    transition: transform 0.5s ease;
  }

  .page.active {
    transform: translateX(0);
  }

  .page.out {
    transform: translateX(-99.99%);
    transition: transform 0.5s ease;
  }

  #projects {
    #my-tiny-room {
      background-image: url(../assets/images/mobile/tinyroom_mobile.webp);
    }

    #proximacentauri {
      background-image: url(../assets/images/mobile/proxima_mobile.webp);
    }

    #haumea {
      background-image: url(../assets/images/mobile/haumea_mobile.webp);
    }

    #minimemo {
      background-image: url(../assets/images/mobile/minimemo_mobile.webp);
    }

    #gradient {
      background-image: url(../assets/images/mobile/gradient_mobile.webp);
    }

    #bloxley {
      background-image: url(../assets/images/mobile/bloxley_mobile.webp);
    }
  }

  .darkMode {
    #projects {
      #haumea {
        background-image: url(../assets/images/mobile/haumea_mobile_dark.webp);
      }

      #bloxley {
        background-image: url(../assets/images/mobile/bloxley_mobile_dark.webp);
      }
    }
  }
}
