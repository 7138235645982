@media (width <= 640px) {
  #hero {
    height: 80svh;
    max-height: 650px;
    padding-top: 0;
  }

  #hero #slogan {
    min-height: 150px;
    font-size: 40px;
    line-height: 45px;
  }

  #hero #hero_bg {
    background-image: url("herobackgroundmobile.93874771.webp");
    background-size: cover;
  }

  #mobile_mockup {
    background-image: url("mobile_mockup.5bee15b7.webp");
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: contain;
  }

  #mobile_mockup_overlay {
    background: linear-gradient(#f3f3f300 0%, #f3f3f3 100%);
  }

  #clients {
    overflow: hidden;
  }

  #clients #clients_container {
    width: 1400px;
  }

  #footer_logo {
    background-size: 100%;
    height: 27px;
  }

  #footer #back_to_top {
    padding: 16px 13px;
  }

  #footer #back_to_top img {
    height: 20px;
    transform: translateX(-4px);
  }

  #menu_bar #menu-container {
    background-color: #0000;
    border: none;
  }

  .expandable-content {
    height: 0;
    transition: height 1s;
    overflow: hidden;
  }

  .animate {
    animation: 15s linear infinite righttoleft;
  }

  @keyframes righttoleft {
    from {
      transform: translateX(0%);
    }

    to {
      transform: translateX(-100%);
    }
  }

  .page {
    width: 100%;
    transition: transform .5s;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(100%);
  }

  .page.active {
    transform: translateX(0);
  }

  .page.out {
    transition: transform .5s;
    transform: translateX(-99.99%);
  }

  #projects #my-tiny-room {
    background-image: url("tinyroom_mobile.348d04be.webp");
  }

  #projects #proximacentauri {
    background-image: url("proxima_mobile.f9383ae7.webp");
  }

  #projects #haumea {
    background-image: url("haumea_mobile.6eac5364.webp");
  }

  #projects #minimemo {
    background-image: url("minimemo_mobile.6587d0a7.webp");
  }

  #projects #gradient {
    background-image: url("gradient_mobile.8fc265f3.webp");
  }

  #projects #bloxley {
    background-image: url("bloxley_mobile.36af42bd.webp");
  }

  .darkMode #projects #haumea {
    background-image: url("haumea_mobile_dark.3233e919.webp");
  }

  .darkMode #projects #bloxley {
    background-image: url("bloxley_mobile_dark.541530a1.webp");
  }
}

/*# sourceMappingURL=index.12e3dd74.css.map */
